import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import waterMark from './../assets/watermark_panel.svg';
import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
    const navigate = useNavigate();

    const calculateTimeLeft = () => {
        const launchDate = new Date('2024-10-20T00:00:00');
        const difference = launchDate - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const handleSiteLive = () => {
        // Prompt the user for their email
        const email = window.prompt('Please enter your email to get notified:');
    
        // Check if the entered email is "muddasir99@gmail.com"
        if (email === 'mirageDev') {
            // If email matches, set the site live
            localStorage.setItem('isSiteLive', 'true');
            alert('Welcome! The site is enabled for testing.');
            navigate('/'); // Redirect to the homepage or any desired route
        } else {
            // If email does not match, show a thank-you message
            alert('Thank you! We will notify you when the site is live.');
        }
    };
    

    const timeComponents = Object.keys(timeLeft).map((interval) => (
        <span key={interval} className="flex flex-col items-center">
            <strong className="text-5xl text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400 drop-shadow-lg font-bold animate-pulse">
                {timeLeft[interval]}
            </strong>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400 drop-shadow-lg capitalize">
                {interval}
            </span>
        </span>
    ));

    return (
        <div className="relative flex-grow h-full overflow-y-auto">
            {/* Watermark */}
            <div
                className="transition-all duration-300 fixed inset-6 sm:mt- sm:inset-20"
                style={{
                    backgroundImage: `url(${waterMark})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    opacity: 1,
                    zIndex: 0, // Ensure watermark stays behind content
                    pointerEvents: 'none',
                }}
            />

            {/* Content */}
            <div className="relative z-10">
                <div className="flex justify-center items-center h-screen">
                    <Container maxWidth="md">
                        <Box className="text-center p-10 bg-white bg-opacity-10 rounded-xl shadow-xl backdrop-blur-md transition transform hover:scale-105 duration-500 ease-in-out animate-fadeIn">
                            <Typography
                                variant="h2"
                                className="mb-4 font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400 drop-shadow-lg"
                            >
                                Coming Soon!
                            </Typography>

                            <Typography
                                variant="body1"
                                className="mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400"
                            >
                                Stay tuned for something amazing. We’re launching soon!
                            </Typography>

                            <div className="flex justify-center space-x-8 mb-6">
                                {timeComponents.length ? timeComponents : <span>Time's up!</span>}
                            </div>

                            <Button
                                variant="contained"
                                color="primary"
                                className="bg-gradient-to-r from-blue-600 to-blue-400 hover:bg-blue-700 text-white py-2 px-6 rounded-full transition-transform duration-300 ease-in-out shadow-lg transform hover:scale-105"
                                onClick={handleSiteLive}
                            >
                                Notify Me
                            </Button>
                        </Box>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
